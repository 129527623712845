<template>
    <standard-page title="New Catalog">
        <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'identities-home'}">Identifier</router-link>
      </span>
            <span>
        <router-link :to="{ name: 'identities-catalogs'}">Catalogs</router-link>
      </span>
            <span>New</span>
        </template>
        <template v-slot:content>
            <div class="az-content-label mg-b-5">New Catalog</div>
            <div class="mg-b-20" v-html="$DEFINITIONS.newCatalog"></div>
            <form @submit.prevent="handleSubmit">
                <div class="pd-30 pd-sm-40 bg-gray-200 wd-xl-100p">
                    <div class="row row-xs">
                        <div class="col-md-5">
                            <input :class="{'is-invalid': submitted && errors.has('name')}"
                                   class="form-control"
                                   id="name"
                                   name="name"
                                   placeholder="Enter Catalog name"
                                   type="text"
                                   v-model="catalog.name"
                                   v-validate="'required'">
                            <div class="invalid-feedback" v-if="submitted && errors.has('name')">{{ errors.first('name') }}</div>
                        </div>
                        <div class="col-md-5">
                            <select :class="{'is-invalid': submitted && errors.has('catalog_type')}" aria-hidden="true" class="form-control select2 select2-hidden-accessible rounded"
                                    data-select2-id="1" data-vv-as="Catalog Type" name="catalog_type" id="catalog_type" v-model="catalog.catalog_type"
                                    v-validate="'required'">
                                <option label="Choose Catalog Type" value="null"></option>
                                <option v-bind:key="index" v-for="(catalog_type, index) in catalog_types">{{catalog_type}}</option>
                            </select>
                            <div class="invalid-feedback" v-if="submitted && errors.has('catalog_type')">{{ errors.first('catalog_type') }}</div>
                        </div>
                        <div class="col-md mg-t-10 mg-md-t-0">
                            <button class="btn rounded btn-az-primary btn-block" type="submit">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </template>
    </standard-page>
</template>

<script>
    import EventBus from '@/event-bus';

    export default {
        name: 'NewCatalog',
        data() {
            return {
                submitted: false,
                catalog: {
                    name: null,
                    catalog_type: null
                },
                catalog_types: ['Individual', 'Sustainability', 'Personal', 'Attestor', 'Trade']
            };
        },
        methods: {
            handleSubmit(e) {
                this.submitted = true;
                this.$validator.validate()
                        .then(valid => {
                            if (valid) {
                                this.createCatalog();
                            }
                        });
            },
            async createCatalog() {
                try {
                    EventBus.$emit('openLoader');
                    await this.$identity.post('identity/catalogs', this.catalog);
                    this.$toastr.s('Catalog Successfully created!', 'Success');
                } catch (e) {
                    this.$toastr.e('Catalog creation failed', 'Error');
                } finally {
                    this.catalog.name = null;
                    EventBus.$emit('closeLoader');
                    await this.$router.push({name: 'identities-catalogs'});
                }
            },
        }
    }
</script>

<style scoped>
</style>